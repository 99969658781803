import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { useQuizEvent } from 'src/sdk/analytics/hooks/useQuizEvent'

type StyleChoiceButtonProps = {
  collection: { img: string; title: string; value: string; description: string }
}

export const StyleChoiceButton = ({ collection }: StyleChoiceButtonProps) => {
  const { dispatch, quizState } = useBuyQuizContext()
  const { sendQuizClickEvent } = useQuizEvent()
  const { heOrShe } = quizState

  const handleClick = (styleChoice: string) => {
    dispatch({ payload: { property: 'style', value: styleChoice } })

    sendQuizClickEvent({
      quiz_category: 'quiz_natal',
      quiz_answer: collection.value,
      quiz_step: heOrShe === 'Ele' ? 'pergunta_04_ele' : 'pergunta_04_ela',
    })
  }

  return (
    <button
      className="fourthQuestion-option"
      onClick={() => handleClick(collection.value)}
    >
      <img
        className="fourthQuestion-option-image"
        src={collection.img}
        alt="style-classic"
      />
      <span className="fourthQuestion-option-title">{collection.title}</span>
      <p className="fourthQuestion-option-description">
        {collection.description}
      </p>
    </button>
  )
}
