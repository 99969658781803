import { useCallback } from 'react'

type Props = {
  quiz_category: 'quiz_natal'
  quiz_answer: string | boolean | null
  quiz_step:
    | 'pergunta_01'
    | 'pergunta_02_ele'
    | 'pergunta_02_ela'
    | 'pergunta_03_ele'
    | 'pergunta_03_ela'
    | 'pergunta_04_ele'
    | 'pergunta_04_ela'
    | 'pergunta_05_ele'
    | 'pergunta_05_ela'
    | 'informacoes_adicionais_step_06'
    | 'como_podemos_te_chamar_step_07'
    | 'qual_o_seu_email_step_08'
    | 'sucess_page_step_09'
  quiz_user_name?: string | null
  quiz_user_email?: string | null
}

export const useQuizEvent = () => {
  window.dataLayer = window.dataLayer || []
  const { dataLayer } = window

  const sendQuizClickEvent = useCallback((props: Props) => {
    dataLayer.push({
      event: 'quiz_event',
      ...props,
    })
  }, [])

  return { sendQuizClickEvent }
}
