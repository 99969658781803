import React, { useState, useEffect } from 'react'
import Button from 'src/components/ui/Button/Button'
import CloseIcon from 'src/images/svg/icon-close'
import IconButton from 'src/components/ui/VivaraIconButton'
import { get } from 'idb-keyval'
import axios from 'axios'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { useQuizEvent } from 'src/sdk/analytics/hooks/useQuizEvent'

import AgePolicyCheckbox from './AgePolicyCheckbox'
import { saveQuizData } from './saveQuizData'

export const setAgeOnCL = async (olderThanEighteenValue: boolean) => {
  try {
    const responseSession = await get('fs::session')
    const person = responseSession?.person

    const response = await axios.post('/api/getClientData', {
      userId: person?.id,
    })

    if (response?.data?.[0]?.id) {
      await axios.post('/api/setOlderThanEighteenCL', {
        olderThanEighteen: olderThanEighteenValue,
        id: response?.data?.[0]?.id,
      })
    }
  } catch (error) {
    console.info('Não foi possível inserir a tag de maior de 18 anos na CL.')
  }
}

export const AgePolicyModal = () => {
  const [ageSelected, setAgeSelected] = useState<boolean>(false)
  const { quizState, dispatch } = useBuyQuizContext()
  const { sendQuizClickEvent } = useQuizEvent()

  if (document) {
    document.querySelector('body')?.classList.add('no-scroll')
  }

  useEffect(() => {
    return () => {
      document.querySelector('body')?.classList.remove('no-scroll')
    }
  }, [])

  const handleSubmit = () => {
    dispatch({
      payload: { property: 'olderThanEighteen', value: ageSelected },
    })

    setAgeOnCL(ageSelected)

    saveQuizData({
      ...quizState,
      olderThanEighteen: ageSelected,
    })

    sendQuizClickEvent({
      quiz_category: 'quiz_natal',
      quiz_answer: ageSelected,
      quiz_step: 'informacoes_adicionais_step_06',
    })
  }

  const backToPriceRange = () => {
    dispatch({ payload: { property: 'priceRange', value: null } })
  }

  return (
    <div className="buy-quiz-ageModal">
      <div className="buy-quiz-ageModal-overlay" />
      <div className="buy-quiz-ageModal-container">
        <IconButton
          icon={<CloseIcon />}
          aria-label="Close"
          onClick={backToPriceRange}
          isCloseButton
        />
        <div className="buy-quiz-ageModal-container-subContainer">
          <span className="buy-quiz-ageModal-container-subContainer-title">
            Informações adicionais
          </span>
          <p className="buy-quiz-ageModal-container-subContainer-subtitle">
            Faltam apenas algumas informações para você receber seu resultado.
          </p>
          <div className="buy-quiz-ageModal-container-subContainer-line" />
          <AgePolicyCheckbox
            setAgeSelected={setAgeSelected}
            ageSelected={ageSelected}
          />
          <Button
            variant="primary"
            className={`NameQuestion-button NameQuestion-button-${
              !ageSelected ? 'disabled' : 'enabled'
            } buy-quiz-ageModal-container-subContainer-button`}
            disabled={!ageSelected}
            onClick={handleSubmit}
          >
            <span className="NameQuestion-button-label">Prosseguir</span>
          </Button>
        </div>
      </div>
    </div>
  )
}
