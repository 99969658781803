import React, { useEffect, useState } from 'react'
import type {
  Filter_FacetsFragment,
  IStoreSelectedFacet,
} from '@generated/graphql'
import type { SearchSort } from '@faststore/sdk/dist/types'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { useQuizEvent } from 'src/sdk/analytics/hooks/useQuizEvent'
import { hashData } from 'src/utils/hashData'
import Filter from 'src/components/search/Filter'
import IconGift from 'src/images/svg/icon-gift'

import { CustomGallery } from '../../CustomGallery/CustomGallery'
import { CustomFilterMobileButton } from '../../CustomFilter/CustomFilterMobileButton'
import { createDefaultFacets } from './quizUtils'

import './style.scss'

export const QuizResult = () => {
  const { quizState, dispatch } = useBuyQuizContext()

  const defaultFacets = createDefaultFacets(quizState)

  const [selectedFacets, setSelectedFacets] = useState<IStoreSelectedFacet[]>([
    ...defaultFacets,
  ])

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  const [filteredFacets, setFilteredFacets] = useState<Filter_FacetsFragment[]>(
    []
  )

  const [sort, setSort] = useState<SearchSort>('score_desc')

  const [isGalleryEmpty, setIsGalleryEmpty] = useState(false)

  const { sendQuizClickEvent } = useQuizEvent()

  const { email, olderThanEighteen, name } = quizState

  const formatTitle = (): string => {
    let title = `Para ${quizState.heOrShe}, ${quizState.style},`

    const [, maxPriceValue] = quizState.priceRange?.split(':') as string[]

    title +=
      maxPriceValue === '999999'
        ? ' Acima de R$ 2500'
        : ` Até R$ ${maxPriceValue}`

    return title
  }

  const handlerNewResult = () => {
    const quizResult = {
      ageGroup: null,
      email,
      giftedPerson: null,
      heOrShe: null,
      name,
      olderThanEighteen,
      priceRange: null,
      style: null,
    }

    dispatch({
      payload: quizResult,
    })

    window?.sessionStorage?.setItem('resultQuiz', JSON.stringify(quizResult))
  }

  useEffect(() => {
    if (selectedFacets.length <= 1) {
      setSelectedFacets(defaultFacets)
    }
  }, [defaultFacets, selectedFacets])

  useEffect(() => {
    if (!name || !email) {
      return
    }

    const sendQuizEvent = async () => {
      try {
        const hashedName = await hashData(name)
        const hashedEmail = await hashData(email)

        sendQuizClickEvent({
          quiz_category: 'quiz_natal',
          quiz_answer: null,
          quiz_step: 'sucess_page_step_09',
          quiz_user_name: hashedName,
          quiz_user_email: hashedEmail,
        })
      } catch (error) {
        console.error('Error while set quiz event', error)
      }
    }

    sendQuizEvent()
  }, [name, email, sendQuizClickEvent])

  return (
    <div className="quizPageResult">
      <h1 className="quizPageResult--name">{quizState.name}</h1>
      <div className="quizPageResult--divider" />
      <p className="quizPageResult--subtitle">
        aqui estão as nossas sugestões de presentes
      </p>
      <h2 className="quizPageResult--title-user-choices">{formatTitle()}</h2>
      <div className="quizPageResult--newResult">
        <IconGift />
        <div className="quizPageResult--newResult-message">
          <p>Gostaria de ver recomendações para outra pessoa?</p>
          <button onClick={handlerNewResult}>Fazer outro teste</button>
        </div>
      </div>
      <div className="quizPageResult--filters">
        <Filter
          facets={filteredFacets}
          isOpen={isFilterOpen}
          setIsOpen={setIsFilterOpen}
          selectedFacets={selectedFacets}
          setSelectedFacets={setSelectedFacets}
          filterByUrl={false}
          term=""
          base=""
          defaultFacets={defaultFacets}
          quiz
        />
      </div>
      <CustomFilterMobileButton
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        sort={sort}
        setSort={setSort}
        isGalleryEmpty={isGalleryEmpty}
      />
      <CustomGallery
        selectedFacets={selectedFacets}
        sort={sort}
        setFilteredFacets={setFilteredFacets}
        isGalleryEmpty={isGalleryEmpty}
        setIsGalleryEmpty={setIsGalleryEmpty}
      />
    </div>
  )
}
